import React from 'react'
import { Link } from 'gatsby'

export default ({ children, to, ...rest }) => {
  const checkIfLinkIsInternal = to && /^\/(?!\/)/.test(to)

  const Button = () => <button {...rest}>{children}</button>

  const NativeLink = () => (
    <a {...rest} href={to}>
      {children}
    </a>
  )
  const GatsbyLink = () => (
    <Link {...rest} to={to}>
      {children}
    </Link>
  )

  return to ? (
    checkIfLinkIsInternal ? (
      <GatsbyLink />
    ) : (
      <NativeLink />
    )
  ) : (
    <Button />
  )
}
