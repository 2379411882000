import React from 'react'
import Seo from './seo'
import styles from '../sass/components/layout.module.scss'
import '../sass/core/reset.scss'
import '../sass/core/typography.scss'

//fonts
import '../fonts/BrandonText/stylesheet.css' //Brandon Text
import '../fonts/AkzidenzGroteskPro/stylesheet.css' //Akzidenz-Grotesk Pro
import '../fonts/AkzidenzGroteskProCondensed/stylesheet.css' //Akzidenz-Grotesk Pro Condensed
import '../fonts/PoplarKozel/stylesheet.css' //PoplarKozel
import '../fonts/ApexSerif/stylesheet.css' //ApexSerif

export default class Layout extends React.Component {
  state = { vh: null }

  calculateVh = () => {
    const vh = window.innerHeight * 0.01 + 'px'
    this.setState({ vh })
  }

  componentDidMount() {
    this.calculateVh()

    window.addEventListener('resize', this.calculateVh)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateVh)
  }

  render() {
    return (
      <div
        className={`${styles.layout} ${this.props.type &&
          styles['layoutWithbg']}`}
        {...this.props}
        style={{ '--vh': this.state.vh || '' }}
      >
        <Seo />
        {this.props.children}
      </div>
    )
  }
}
