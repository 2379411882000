import React from 'react'

export default () => (
	<div>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="-399 121 100 59">
			<title>Pilsner Urquell</title>
			<g>
				<path
					fill="currentColor"
					d="M-299.7,165.7L-299.7,165.7L-299.7,165.7l0-0.2v-0.1l0-0.1c0,0,0,0,0,0l0-0.1l0-0.1l0-0.1l0,0l-0.1,0l0.1,0
			l0.1-0.1l0,0l0-0.1c0,0,0,0,0,0l0-0.1l0-0.1l0-0.1l0,0l0-0.1c0,0,0,0,0,0l0-0.1l0,0l-0.1-0.1l-0.1-0.1l-0.1,0l0,0l-0.1,0h0l-0.1,0
			l-0.1,0l-0.5,0v1.5l0.3,0v-0.6l0.1,0l0.1,0l0.1,0l0.1,0l0,0l0,0l0,0l0,0.1l0,0.1l0,0.1v0.1l0,0l0,0l0,0.1L-299.7,165.7
			L-299.7,165.7L-299.7,165.7z M-300.1,164.8L-300.1,164.8L-300.1,164.8l-0.1,0.1l-0.1,0l-0.1,0l-0.1,0l-0.2,0v-0.4l0.2,0l0.1,0
			l0.1,0l0.1,0l0,0l0,0l0,0l0,0L-300.1,164.8L-300.1,164.8z"
				/>
				<path
					fill="currentColor"
					d="M-299,164.9L-299,164.9l0-0.2l0,0l-0.1-0.2l0,0l-0.1-0.1l0,0l-0.1-0.1l-0.1-0.1l-0.1-0.1l0,0l-0.1-0.1l0,0
			l-0.1-0.1l0,0l-0.2-0.1l0,0l-0.2,0l-0.2,0l-0.2,0h0l-0.2,0l0,0l-0.2,0l0,0l-0.1,0.1l0,0l-0.1,0.1l-0.1,0.1l-0.1,0.1l0,0l-0.1,0.1
			l0,0l-0.1,0.1l0,0l-0.1,0.2l0,0l0,0.2l0,0.2l0,0.2v0l0,0.2l0,0l0.1,0.2l0,0l0.1,0.1l0,0l0.1,0.1l0.1,0.1l0.1,0.1l0,0l0.1,0.1l0,0
			l0.1,0.1l0,0l0.2,0.1l0,0l0.2,0l0.2,0l0.2,0l0,0l0.1,0l0,0l0.1,0l0,0l0.1,0l0,0l0.1-0.1l0,0l0.1-0.1l0.1-0.1l0.1-0.1l0,0l0.1-0.1
			l0,0l0.1-0.1l0,0l0.1-0.2l0,0l0-0.2l0-0.2v0L-299,164.9z M-299.3,165.2l0,0.1l0,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
			l-0.1,0.1l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l0-0.1l0-0.1l0-0.1
			l0-0.1l0-0.1l0-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0l0.1,0l0.1,0l0.1,0l0.1,0l0.1,0l0.1,0.1l0.1,0.1l0.1,0.1
			l0.1,0.1l0.1,0.1l0.1,0.1l0,0.1l0,0.1l0,0.1L-299.3,165.2z"
				/>

				<path
					fill="currentColor"
					d="M-313.6,164.1c2.3-0.4,2.9-2.5,2.6-3.6c-0.3,0.5-0.9,1.9-2.4,2c-1.6,0.2-1.7-1.8-1.6-3l4.1-1.9
				c0.1-0.4,0.3-1.2,0.2-2.2c-0.2-1.1-1.1-2.7-3.2-2.3c-2.3,0.4-3.3,2.4-3.3,5.9C-317.3,161.4-316.2,164.5-313.6,164.1z M-313.9,154
				c0.8-0.1,1.1,0.8,1.2,1.4c0,0.5,0,0.8-0.1,1.4l-2.2,1C-315.1,155.8-314.7,154.1-313.9,154z"
				/>
				<path
					fill="currentColor"
					d="M-309.7,150v12c0,0.7-0.3,1.4-0.7,2.1l-0.1,0.1l3.7,0.5l-0.1-0.1c-0.5-0.7-0.7-1.4-0.7-2.1v-14.3l-2.9-0.4
				l0.1,0.1C-310,148.5-309.7,149.3-309.7,150z"
				/>
				<path
					fill="currentColor"
					d="M-324.5,154.9v5.3c0,2,0.7,3.1,1.9,3.2c0.8,0,1.8-0.6,2-1.7v1.5l2.9,0.2l-0.1-0.1
				c-0.5-0.6-0.7-1.4-0.7-2.2v-6c0-0.7,0.1-2,0.1-2l-3-0.3l0.1,0.1c0.5,0.7,0.7,1.4,0.7,2.1v5.3c-0.4,0.6-0.8,0.8-1.1,0.8
				c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.5-0.2-0.9v-5c0-0.7-0.2-1.5-0.4-2.2l0,0l-2.6-0.1l0.1,0.1
				C-324.8,153.4-324.5,154.1-324.5,154.9z"
				/>
				<path
					fill="currentColor"
					d="M-338.2,154.9v6c0,0.7-0.2,1.4-0.7,2.1l-0.1,0.1l3.6-0.1h0.1l-0.1-0.1c-0.5-0.7-0.7-1.3-0.7-2v-5.4
				c0.3-0.5,0.7-0.8,1.2-0.8c0.4,0,0.8,0.4,1.1,0.9l0,0.1l1-2.7c-0.3-0.4-0.7-0.6-1.2-0.6c-0.8,0-1.8,0.9-2,1.8v-1.7l-2.9,0l0.1,0.1
				C-338.4,153.4-338.2,154.2-338.2,154.9z"
				/>
				<path
					fill="currentColor"
					d="M-351.7,164c-0.5-0.6-0.7-1.3-0.7-2v-5.1c0.4-0.7,0.8-1,1.3-1c0.4,0,0.8,0.3,1,0.8l0.1,0.1l1-2.8l0,0l0,0
				c-0.3-0.3-0.6-0.5-1-0.5c-0.8,0-2.1,1-2.3,2v-1.7l-2.9,0.3c0.5,0.6,0.7,1.4,0.7,2.1v5.9c0,0.7-0.3,1.5-0.7,2.2l-0.1,0.1
				L-351.7,164L-351.7,164z"
				/>
				<path
					fill="currentColor"
					d="M-347.9,152.5v7.4c0,0.3,0,0.5,0,0.8c-0.2,1.4-0.9,2.6-1.7,2.9l-0.1,0l0.1,0c0.1,0,0.3,0.1,0.4,0.1
				c0.6,0,1.6-0.3,2.1-0.8c0.4,0.4,0.8,0.7,1.4,0.7c1.3,0,2.9-0.6,3-2.4v2.1l3.5-0.2l-0.1-0.1c-0.6-0.7-0.9-1.7-0.9-2.6v-11.1
				c0-0.8,0.1-2.6,0.1-2.6l-3.6,0.3l0.1,0.1c0.6,0.8,0.9,1.6,0.9,2.5v9.8c-0.5,1.1-1.3,1.6-1.9,1.6c-0.2,0-0.4-0.1-0.5-0.3
				c-0.1-0.2-0.2-0.5-0.2-1V152c0-1.5-0.8-2.6-1.6-3.5c-0.6-0.8-1.2-1.5-1.2-2.4c0-0.6,0.2-1.3,0.6-1.8l0.1-0.1l-0.1,0
				c-0.8,0-3,1.1-3,2.9c0,1.2,0.7,2,1.4,2.8C-348.5,150.7-347.9,151.4-347.9,152.5z"
				/>
				<path
					fill="currentColor"
					d="M-305.6,150.6v12c0,0.7-0.3,1.4-0.7,2.1l-0.1,0.1l3.7,0.6l-0.1-0.1c-0.5-0.7-0.7-1.4-0.7-2.1v-14.2
				l-2.9-0.5l0.1,0.1C-305.9,149.1-305.6,149.9-305.6,150.6z"
				/>
				<path
					fill="currentColor"
					d="M-376.1,165.9c0,1.2,1,2.7,3,2.4c1.6-0.3,3-1.4,2.7-3.8c-0.2-1.4-1.4-2.4-2.4-3.5c-0.7-0.7-1.4-1.2-1.4-2
				c0-0.6,0.5-0.8,0.8-0.8c0.4,0,1.6,0.6,2.1,1.7l0.7-2.5c-0.3-0.2-1.2-0.4-1.9-0.5c-0.3,0-0.7,0-0.9,0.1c-1.5,0.3-2.5,1.7-2.5,3.2
				c0,1.2,0.8,2.3,1.7,3.1c0.8,0.8,2,1.7,2.3,2.6c0.2,0.8-0.4,1.2-1,1.2c-0.8,0-1.5-0.7-1.7-1.3c-0.3-1,0.3-1.6,0.3-1.6
				C-375.3,164.2-376.1,165-376.1,165.9z"
				/>
				<path
					fill="currentColor"
					d="M-383.6,160.9c0,0,0.8,0.2,0.8,1.6v6.3c0,0.7-0.3,1.5-0.7,2.4l-0.1,0.1l3.7-1.2l0.1,0l-0.1-0.1
				c-0.5-0.5-0.7-1.1-0.7-1.8v-8.3L-383.6,160.9z"
				/>
				<path
					fill="currentColor"
					d="M-389.8,174.9l0-6c3.4-2.8,5.9-7.9,6.3-12.3c0.1,0,0.7-0.1,0.5,0.8c-0.2,1,0.5,1.5,1.2,1.3
				c0.6-0.2,1.2-0.6,1.2-1.6c-0.1-1.1-0.8-1.8-2.4-1.6c-0.1,0-0.4,0-0.5,0c-0.1-6.5-3.5-9.3-6.8-9.4c-3.3-0.1-5.7,2.1-6.8,3.1
				c-0.1,0.1-0.2,0.2-0.3,0.2l-0.1,0.1l0.1,0c0.5,0.2,1.1,0.1,1.2,1.1c0,0.4-0.4,1.5-0.8,2.7c-0.5,1.6-1,2.6-1,3.6
				c-0.1,1.2,0.3,2.1,1.3,2.3c0.6,0.1,1.4-0.3,1.5-1.1c0.1-0.6-0.3-1.3-1-1.2c-0.6,0.1-0.8-0.2-0.8-0.5c0.1-3.2,3.4-6.3,6.2-6.3
				c2.5,0.1,4.1,2.4,4.2,5.9v0c-0.8,0.2-1.6,0.5-2.4,0.9v1.5c0.8-0.5,1.7-1,2.5-1.3c-0.2,2.5-0.9,5-1.9,7c0,0,0,0.1,0,0.1
				c-0.3,0.7-0.7,1.3-1.2,1.8c0,0-0.1,0.1-0.1,0.1v0c-0.5,0.6-1.1,1.1-1.9,1.6c0.9-0.8,1.5-1.8,1.9-2.8l0-4.2h0v-1.5l0-0.2l0,0v-1.8
				c0,0,0,0,0,0l0-2.4c0-1.7,1-2.7,1-2.7l-5.5,1.8c0,0,1.6,0.4,1.6,1.9l0,3.1c-3.9,2.6-6.2,5.7-6.2,8.4c0,2.3,1.2,3.7,3.2,3.8
				c1,0,2-0.2,3.1-0.6l0,4.7c-0.3,3.2-1.7,3.5-2.2,3.8c-0.3,0.1-0.8-0.1-0.5-0.3c0.4-0.2,0.7-0.8,0.5-1.4c-0.2-0.7-0.9-0.9-1.5-0.7
				c-0.8,0.3-1,1-0.8,1.8c0.4,1.2,1.5,1.6,3.1,0.9c0.2-0.1,6.2-2.6,6.2-2.6S-389.8,176.6-389.8,174.9z M-394.2,169
				c-1.1,0-1.8-0.8-1.8-2.2c0-2.3,1.7-4.3,3.1-5.5l0.1,7.4C-393.3,168.9-393.7,169-394.2,169z"
				/>
				<path
					fill="currentColor"
					d="M-369.9,167.2l-0.1,0.1l3.7-0.8l-0.1-0.1c-0.5-0.6-0.7-1.2-0.7-1.9v-5.1c0.4-0.7,0.9-1.2,1.3-1.2
				c0.4,0,0.6,0.4,0.6,1.1v4.7c0,0.7,0.2,1.4,0.4,2.1l0,0l2.9-0.6l-0.1-0.1c-0.7-0.5-1.1-1.2-1.1-1.8v-5.2c0-1.7-0.6-2.7-1.6-2.8
				c-0.8,0-2,0.9-2.4,2.2v-1.5l-2.9,0.6l0.1,0.1c0.5,0.5,0.7,1.3,0.7,2v5.8C-369.2,165.6-369.4,166.4-369.9,167.2z"
				/>
				<path
					fill="currentColor"
					d="M-379.7,169.9l-0.1,0.1l3.7-1.1l0.1,0l-0.1-0.1c-0.5-0.5-0.7-1.2-0.7-1.9v-14.3l-2.9,0.9l0.1,0.1
				c0.5,0.5,0.7,1.2,0.7,1.9v12C-378.9,168.3-379.2,169.1-379.7,169.9z"
				/>
				<path
					fill="currentColor"
					d="M-359.7,167.8c-8,1.2-15.7,3.3-24.3,6.2c-0.5,0.2-0.9,0.2-1.2,0.2c-0.5,0-0.8-0.3-1-0.8
				c-0.1-0.3-0.1-0.6,0.1-0.9c0.2-0.4,0.7-0.8,1.4-0.9l0.1,0l-0.1-0.1c-0.3-0.2-0.6-0.3-1-0.3c-0.7,0-1.5,0.3-1.9,0.9
				c-0.4,0.6-0.5,1.3-0.3,2c0.4,1.2,1.3,1.7,2.5,1.8c0.5,0,1.1-0.1,1.7-0.3c16.9-5.4,31.9-7.4,54.2-6.9l0.2,0v-3.1h-0.1
				C-340.9,165.4-350.5,166.3-359.7,167.8z"
				/>
				<path
					fill="currentColor"
					d="M-358.3,165.4c2.3-0.4,2.9-2.5,2.6-3.6c-0.3,0.5-0.9,1.9-2.4,2c-1.6,0.2-1.7-1.8-1.6-3l4.1-1.9
				c0.1-0.4,0.3-1.2,0.2-2.2c-0.2-1.1-1.1-2.7-3.2-2.3c-2.3,0.4-3.3,2.4-3.3,5.9C-362.1,162.7-360.9,165.8-358.3,165.4z
				M-358.7,155.3c0.8-0.1,1.1,0.8,1.2,1.4c0,0.5,0,0.8-0.1,1.4l-2.2,1C-359.8,157.1-359.5,155.4-358.7,155.3z"
				/>
				<path
					fill="currentColor"
					d="M-325.7,152.7L-325.7,152.7l-2.8-0.1l-0.1,0v1.5c-0.1-1.2-1-1.6-1.8-1.6c-1.4-0.1-3,2.2-3.1,5.3
				c-0.1,1.9,0.3,3.5,1.2,4.4c0.5,0.5,1,0.8,1.7,0.8c0.9,0,1.9-0.7,2-1.8v11.5l2.9-1.9l-0.1,0c-0.5-0.3-0.7-0.9-0.7-1.6v-14.2
				C-326.4,154.2-326.1,153.4-325.7,152.7z M-328.5,159.1c0,0.8-0.5,1.8-1.3,1.8c-0.2,0-0.4-0.1-0.6-0.3c-0.5-0.5-0.7-1.6-0.7-2.9
				c0.1-2.2,0.8-3.2,1.4-3.2c0.5,0,0.9,0.3,1.2,1l0,3.5C-328.5,159-328.5,159.1-328.5,159.1z"
				/>
				<path
					fill="currentColor"
					d="M-301.9,168.1c-8-1.4-15.1-2.2-20.4-2.4c-1,0-2-0.1-3.2-0.1l-0.1,0v3.3l0,0c8.6,0.7,13.7,1.7,18,3l0,0
				l5.7-3.8L-301.9,168.1z"
				/>
			</g>
			<g className="circle">
				<polygon
					fill="#C32028"
					points="-371.2,138.1 -371.2,138.1 -371.2,138 -371.4,137.7 -371.4,136 -371.4,135.9 -371.6,135.9
			-371.6,137.8 -371.6,137.8 	"
				/>
				<path
					fill="#C32028"
					d="M-358.3,134.2c-0.4-1.9-1.2-3.6-2.5-5c-1.9-2.1-4.7-3.4-7.7-3.4c-4.7,0-8.7,3.1-10,7.4
			c-0.3,0.9-0.4,1.9-0.4,2.9c0,3.9,2.2,7.3,5.4,9.1c1.5,0.8,3.2,1.2,4.9,1.2c5.7,0,10.4-4.6,10.4-10.3
			C-358.1,135.4-358.1,134.8-358.3,134.2z M-359.5,131.8C-359.5,131.8-359.4,131.8-359.5,131.8C-359.4,131.9-359.4,131.9-359.5,131.8
			c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0l-0.3-0.2l-0.1,0l-0.1-0.1L-359.5,131.8z M-360.4,131.6l0-0.1l0.1-0.1l0.1,0.1l0.2,0.5l-0.1,0.1l-0.1-0.1
			c0,0,0,0,0,0c0,0,0,0,0,0l-0.4,0.5l0.2,0l0.4,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1l0.1-0.1l0.1,0.1l0.2,0.3l-0.1,0.1l-0.1-0.1
			l-0.9,0l-0.5,0.2c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l-0.1,0l0,0l-0.3-0.6l0.1-0.1l0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0.4-0.2
			L-360.4,131.6z M-362.9,136.9h-0.1v-0.4l1.4-1.7h0.5v0.1v1.7h0.2h0.2v0.1v0.3h-0.3v0.8h-0.4h-0.1V137v-0.1H-362.9z M-363.4,130.1
			L-363.4,130.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.9-0.9c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1l0.1-0.1
			l0.1,0.1l0.4,0.4l-0.1,0.1l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.4,0.4l0,0l0.2,0l0.6-0.1c0,0,0,0,0,0s0,0,0,0l-0.1-0.1l0.1-0.1
			l0.1,0.1l0.3,0.3l-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.7,0.1l-0.3,0.9l0.1,0.1l-0.1,0.1l-0.2-0.2l-0.1-0.1l0.1-0.1
			l0.2-0.6l0-0.1l-0.1-0.1l-0.4,0.4c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l-0.1,0.1l-0.4-0.4L-363.4,130.1L-363.4,130.1z M-365.2,128.6
			l0.2,0.1l0,0l-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
			c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0
			c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1l-0.2,0.4l0,0l-0.1-0.1l0.1-0.3
			c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
			c0,0,0,0-0.1,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
			c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1
			c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1L-365.2,128.6z M-367.3,126.3L-367.3,126.3l0.2,0.3
			l0.1-0.1l0.2-0.1l0.2,0l0,0.1l-0.3,0.3l-0.2,0l-0.1,0l-0.2-0.5L-367.3,126.3z M-368.1,128.3l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0.2-1.2
			l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l-0.1,0l0-0.1l0.6,0.1l0,0.1l0,0l0.5,1.2l0-0.2l0.1-0.7l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l-0.1,0
			l0-0.1l0.5,0.1l0,0.1l0,0.1l-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.2,1.5l-0.2,0l-0.1,0l-0.4-1.1l-0.1-0.3l-0.2,1.1c0,0,0,0,0,0
			c0,0,0,0,0,0l0.1,0l0,0l0,0.1l-0.4-0.1l-0.1,0L-368.1,128.3z M-365.2,130c1.6,0.9,2.8,2.3,3.4,4h-0.2h-0.1
			c-0.3-0.9-0.8-1.7-1.4-2.3c-1.3-1.4-3.1-2.3-5.1-2.3c-2.8,0-5.3,1.7-6.3,4.1c-0.1,0.2-0.1,0.3-0.2,0.5h-0.1h-0.1
			c0.1-0.2,0.1-0.3,0.2-0.5c1.1-2.6,3.6-4.4,6.6-4.4C-367.3,129.2-366.2,129.5-365.2,130z M-371,141.6c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1,0-0.1-0.1-0.2-0.1h5.9c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2H-371z M-367.4,142c-0.2,0-0.4,0.1-0.6,0.1
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6-0.1H-367.4z M-372.1,140.9c-0.1,0-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1
			h7.8c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2L-372.1,140.9z M-372.9,140.3c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1h9.1
			c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2H-372.9z M-373.4,139.6L-373.4,139.6c-0.1-0.1-0.1-0.2-0.2-0.2l10.1,0
			c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2H-373.4z M-372,133.5l-0.5-0.6h0.6v-0.7c0.1-0.1,0.1-0.2,0.2-0.4
			c-0.1-0.1-0.1-0.3-0.1-0.5h0.6c0,0,0,0.1,0,0.1c0,0.2,0,0.3-0.1,0.4c0,0.1,0.1,0.1,0.1,0.2h0.1h1l0.4-0.4h0.5v-0.4
			c0-0.3,0.2-0.6,0.5-0.7c0,0,0,0,0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1
			c0,0,0,0,0,0c0.2,0.1,0.3,0.4,0.3,0.6v0.4h0.2h0.3l0.1,0.1l0.3,0.4h0.1h1c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.3-0.1-0.5h0.6
			c0,0,0,0.1,0,0.1c0,0.2,0,0.3-0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.4v0.7h0.2h0.4l-0.1,0.1l-0.4,0.5l0,0.1v0.4v0.3l0.3,0.5
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0.2,0.1,0.3,0.4,0.3,0.7h0.2h0.2v0.1v3.1h-0.9h-0.1V137h0.5v-0.1v-0.1h-0.7v2h-0.8h-0.1v-0.9
			l0.2-0.1v-1.8h-0.1l-0.1-0.4h0.2v0c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.3-0.5-0.4-0.8-0.4c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0v0h0.2h0
			l0,0l-0.1,0.3h-0.1v1.8l0.2,0.1v0.9h-0.9h-0.1v-0.9l0.2-0.1v-1.8h-0.1l-0.1-0.4h0.2v0c0,0,0,0,0,0c0-0.3-0.1-0.5-0.3-0.7
			c-0.2-0.3-0.5-0.4-0.8-0.4c-0.6,0-1.1,0.5-1.1,1.2v0h0.2h0l0,0l-0.1,0.3h-0.1v1.8l0.2,0.1v0.9h-1.4h-0.1V137h0.5v-0.1v-0.1h-0.7v2
			h-0.3h-0.1v-3.2h0.3c0-0.4,0.2-0.7,0.6-0.8l0.3-0.5v-0.6v-0.1L-372,133.5z M-376.2,136.2C-376.2,136.2-376.2,136.2-376.2,136.2
			c-0.1-0.1-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1h0c0.1,0.1,0.2,0.1,0.2,0.2
			c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0.1,0
			c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1
			c-0.1,0-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3
			c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1C-376.2,136.3-376.1,136.3-376.2,136.2c0.1,0.1,0.1,0.1,0.1,0.1
			C-376.1,136.3-376.1,136.2-376.2,136.2z M-369.7,126.9l0,0.1l0.1,0.3l-0.1,0l-0.1,0l0-0.1l0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.4,0.1
			l0.1,0.6l0.1,0l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.2l0.1,0l0,0.1l0.1,0.5l-0.1,0l-0.1,0l0-0.1l0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			l-0.3,0l0.1,0.6l0.1,0l0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0-0.2l0,0l0,0l0.1,0l0,0.1l0.1,0.3l-1.1,0.2l-0.1,0l0-0.1l0.1,0
			c0,0,0,0,0,0c0,0,0,0,0,0l-0.2-1.2l0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0,0l-0.1,0l0-0.1L-369.7,126.9z M-372.5,127.7l0.1,0.1l0,0l0,1.6
			l0.2-0.1l0.3-0.2c0,0,0,0,0,0l-0.1-0.3l0.1-0.1l0.1,0.1l0.2,0.3l-1,0.5l0,0l-0.1-0.1l0-1.4l0-0.2l-0.4,0.2c0,0,0,0,0,0l0.1,0.3
			l-0.1,0.1l0,0l-0.2-0.4L-372.5,127.7z M-375.2,129.6L-375.2,129.6l0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l1,0.9l0,0l0.2-0.3
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.2-0.2l0.1-0.1l0.1,0.1l0.3,0.2l-0.7,0.7l-0.1,0.1l-0.1-0.1l0.1-0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.9-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0.1l-0.1-0.1L-375.2,129.6z M-377,132.1
			c0,0,0-0.1,0.1-0.1s0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0.1
			c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0,0.1l0.2,0.1l0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.1l0.1,0.1l-0.1,0.2l-0.2,0.4l0,0.1l-0.1-0.1l0-0.1l0,0c0,0,0,0,0,0
			c0,0,0,0,0,0l-1.2-0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0.1l-0.1-0.1L-377,132.1z M-378.3,137.7v-0.2l0.4,0c0,0,0,0,0.1,0
			c0,0,0,0,0-0.1v-1.9v-0.2l-0.5,0.1l-0.1,0v-0.2l0.9-0.3h0.3v0.1v2.5c0,0,0,0,0,0.1c0,0,0,0,0.1,0l0.1,0l0.3,0v0.1v0.1h-1.4H-378.3z
			M-376.1,140.8c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0-0.1l-0.4,0.2c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l-0.1,0l0,0l-0.3-0.6l0.1-0.1l0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0l0,0l1.1-0.6c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1l0.1-0.1l0.1,0.1l0.2,0.4c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
			C-376,140.8-376,140.8-376.1,140.8z M-374,142.4c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
			c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
			c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1l-0.1,0.1
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
			c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1l0,0l-0.4,0.4c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1
			l-0.1,0.1l-0.4-0.4l-0.1-0.1l0.1-0.1l0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.9-0.9c0,0,0,0,0,0c0,0,0,0,0,0
			l-0.1-0.1l0.1-0.1l0.1,0.1l0.4,0.4c0,0,0,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
			C-374,142.3-374,142.4-374,142.4z M-372,143.4l-0.3,1.6c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l-0.1,0.1l-0.4-0.2l-0.1-0.1l0.1-0.1l0.1,0
			c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.3l0-0.1l-0.4-0.2l-0.3,0.3c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l-0.1,0.1l-0.3-0.2l-0.1-0.1l0.1-0.1
			l0.1,0l1.2-1.2L-372,143.4L-372,143.4z M-369.4,144.3L-369.4,144.3l-1,1.3l0.2,0l0.3,0.1c0,0,0,0,0,0l0.1-0.3l0.2,0l0,0.1l-0.1,0.4
			l-1-0.2l-0.1,0l0-0.1l0.9-1.1l0.1-0.1l-0.5-0.1c0,0,0,0,0,0l-0.1,0.3l0,0l-0.1,0l0.1-0.4L-369.4,144.3L-369.4,144.3z M-366.7,145.3
			c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1l-0.6,0.1l-0.1,0l0-0.1l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
			l-0.1-1.2l0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0,0l-0.1,0l0-0.1l0.7-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0
			c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3C-366.7,145-366.7,145.1-366.7,145.3z M-363.7,144.6
			C-363.7,144.6-363.8,144.7-363.7,144.6c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1
			c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0,0l0.2,0.5c0,0,0,0,0,0
			c0,0,0,0,0,0l0.1,0l0.1,0l0.1,0.1l0,0l-0.5,0.3l-0.1,0l-0.1-0.1l0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.5-1l-0.1-0.1c0,0,0,0,0,0
			c0,0,0,0,0,0l-0.1,0l-0.1,0l-0.1-0.1l0.7-0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1
			c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0-0.1,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
			L-363.7,144.6C-363.7,144.6-363.7,144.6-363.7,144.6z M-368.6,143.3c-2.1,0-4-0.9-5.3-2.3c-0.6-0.7-1.1-1.5-1.5-2.4h0.2
			c0,0,0,0.1,0,0.1c0.6,1.6,1.8,2.9,3.4,3.7c0.9,0.5,2,0.7,3.1,0.7c3,0,5.5-1.9,6.5-4.5h0.2c0,0,0,0.1,0,0.1
			C-362.9,141.4-365.5,143.3-368.6,143.3z M-361.7,142.9c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0
			c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3
			c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1
			c0,0,0,0,0,0c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3C-361.6,142.7-361.6,142.8-361.7,142.9z
			M-360.2,141.1C-360.2,141.1-360.2,141.2-360.2,141.1C-360.3,141.2-360.3,141.2-360.2,141.1C-360.3,141.2-360.3,141.2-360.2,141.1
			c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0l-0.3-0.1l-0.1-0.1l0.1-0.2l0.3,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1l-0.8-0.4c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0l-0.1,0.1l-0.1-0.1l0.3-0.6l0.1,0.1l-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0l0.1,0.1l0.6,0.3c0,0,0,0,0.1,0
			c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2C-360.1,141-360.2,141-360.2,141.1z M-358.5,136.9v0.7h-2h-0.1
			v-0.3c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3s0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3
			c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0
			c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0v0.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
			c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0.1,0
			c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.6,0.4h0.3h0.9l0.1-0.4L-358.5,136.9L-358.5,136.9z"
				/>
				<g>
					<path
						fill="#C32028"
						d="M-353.6,131.7c-0.4-1.1-2.3-2.7-2.3-2.7s-0.1-1.1-0.6-2.1c-0.5-1-1.1-1.4-1.9-1.9
				c-0.9-0.4-1.2-0.6-1.4-1.2c-0.3-0.5-0.7-0.8-1.4-1.3c-0.8-0.5-2.1-0.3-2.9-0.3c-0.8,0.1-1.2,0-2-0.1c-0.8-0.1-1.9-1.1-3.5-1.2
				c-1.4,0-3.6,1.3-4.3,1.7c-0.1,0-0.1,0-0.2,0.1c-0.4,0.1-0.9-0.3-2.8,0.5c-1.4,0.5-1.7,1.8-1.9,2.6c-0.3,0.2-0.5,0.5-0.7,0.9
				c-0.5,0.2-1.4,0.7-2.6,1.9c-1.8,1.8-1,5.3-0.9,5.8c0.1,0.5-0.3,1.2-0.8,3.4c-0.2,1,0.4,2,1.2,2.8c0.7,0.9,1.2,0.9,1.8,1.9
				c0.6,1,0.1,1.1,0.6,2.1c0.5,1,1.1,1.4,1.9,1.9c0.9,0.4,1.2,0.6,1.4,1.2c0.3,0.5,0.7,0.8,1.4,1.3c0.8,0.5,2.1,0.3,2.9,0.3
				c0.8-0.1,1.2,0,2,0.1c0.8,0.1,1.9,1.1,3.5,1.2c1.6,0.1,2-0.8,3.9-1.5c1.2-0.4,1.4,0.1,3.3-0.7c2.3-0.9,1.4-2.4,2.1-3.2
				c0.5-0.6,1.3-0.8,2.2-1.3c1.5-0.3,2.7-2.5,2.7-5.1c0-1.1-0.2-2.2-0.6-3C-353.2,135.6-353,133.4-353.6,131.7z M-355.2,136.6
				C-355.2,136.5-355.2,136.5-355.2,136.6c0.2,0.4,0.3,1,0.3,1.5c0.1,1.6-0.7,1.3-0.7,2.6c-0.1-0.3-0.2-0.6-0.2-0.9
				C-356.2,137.8-355.1,138.3-355.2,136.6z M-357.2,127.5c0.6,0.7,0.3,1,0.5,2c0.2,0.6,0.1,1.6,0.1,1.6s-0.2-2.8-2.4-5.2
				C-359,125.8-357.8,126.7-357.2,127.5z M-380.8,129.5c-1.7,1.6-0.9,4.7-0.9,5.1c0.1,0.4-0.3,1-0.7,3c-0.1,0.5,0,0.9,0.3,1.4
				c-0.5-0.6-0.9-1.3-0.7-2c0.4-1.9,0.8-2.6,0.7-3c0-0.4-0.8-3.5,0.9-5.1c1.2-1.1,2-1.5,2.5-1.7c0.2-0.3,0.4-0.6,0.6-0.8
				c0.2-0.7,0.5-1.8,1.8-2.3c1.8-0.7,2.3-0.3,2.6-0.4c0,0,0.1,0,0.1-0.1c0.6-0.3,2.7-1.5,4-1.5c1.5,0,2.6,0.9,3.3,1
				c0.7,0.1,1.2,0.1,1.9,0.1c0.7,0,2-0.2,2.7,0.2c0.7,0.5,1.1,0.7,1.4,1.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c-0.2-0.2-0.5-0.4-0.9-0.7
				c-0.7-0.5-2-0.3-2.7-0.2c-0.7,0-1.2,0-1.9-0.1c-0.7-0.1-1.8-1-3.3-1c-1.4,0-3.4,1.2-4,1.5c0,0-0.1,0-0.1,0.1
				c-0.4,0.1-0.8-0.2-2.6,0.4c-1.3,0.5-1.6,1.6-1.8,2.3c-0.3,0.2-0.5,0.5-0.6,0.8C-378.8,128-379.6,128.4-380.8,129.5z M-378.7,145.1
				c-1-1.2-0.2-1.5-1-2.5c0.2,0.2,0.5,0.4,0.7,0.6c1.5,1.4,0.3,1.6,1.4,3c0,0,0,0,0,0.1C-378,145.9-378.4,145.6-378.7,145.1z
				M-378.2,143.3c-0.3-0.5-1.5-1.6-1.9-4.1c-0.3-2.5-0.2-3.5-0.2-4.2c0-0.3,0-1.1,0.2-2c0.2-1.1,0.7-2.5,1.5-3.3c0,0,2.1-2.7,3.6-4
				c1.7-1.3,3.6-1.3,4.7-1.4c1.1-0.1,4.2-0.7,8.1,1.6c0,0,2.4,1.4,3.2,2.6c0.8,1.2,1.8,2.6,1.9,4.7l0.1,1l0.1,1c0,0,0,1.5-0.2,2.5
				c-0.2,1,0,2.1-0.9,3.6c-0.2,0.3-0.6,0.8-0.6,0.8c1.2-2,1.1-3.1,1.2-4.1c0.2-1,0.2-2.4,0.2-2.4l-0.2-1.3l-0.1-0.5
				c-0.1-2.1-1-3.4-1.8-4.5c-0.7-1.1-3-2.5-3-2.5c-3.7-2.2-6.6-1.6-7.7-1.5c-1,0.1-2.9,0.1-4.5,1.3c-1.5,1.2-3.5,3.8-3.5,3.8
				c-0.7,0.7-1.1,1.7-1.4,2.7c-0.3,1-0.3,1.9-0.3,2.3c0,0.7-0.1,1.7,0.2,4c0.3,2.4,1.6,3.4,1.9,3.8c0.3,0.4,1.2,1.2,2.5,2.1
				c1.3,0.9,2.7,1.3,2.7,1.3c3.1,0.9,5,0.6,5.5,0.4c0.5-0.1,1.9-0.5,1.9-0.5s-1.5,0.5-2.2,0.6c-0.6,0.1-2.6,0.5-5.8-0.4
				c0,0-1.5-0.5-2.9-1.4C-376.8,144.4-378,143.7-378.2,143.3z M-368.5,146.6c-3.1,0-5.9-1.3-7.8-3.4c-1.7-1.9-2.8-4.4-2.8-7.1
				c0-1,0.2-2,0.4-2.9c1.3-4.4,5.4-7.6,10.2-7.6c1.9,0,3.7,0.5,5.2,1.4c2.7,1.5,4.7,4.2,5.3,7.3c0.1,0.6,0.2,1.2,0.2,1.9
				C-357.8,141.9-362.6,146.6-368.5,146.6z M-356.4,143.1c-0.8,0.5-1.6,0.7-2.1,1.3c-0.6,0.8,0.2,2.2-2,3.2c-1.8,0.8-2.1,0.3-3.2,0.7
				c-1.8,0.6-2.2,1.5-3.7,1.5c-1.5-0.1-2.6-1.1-3.3-1.2c-0.7-0.1-1.2-0.2-1.9-0.1c-0.7,0.1-2.1,0.3-2.8-0.3c-0.2-0.2-0.4-0.3-0.6-0.4
				c0.7,0.3,1.8,0.1,2.5,0.1c0.7-0.1,1.2,0,1.9,0.1c0.7,0.1,1.8,1.1,3.3,1.2c1.5,0.1,1.9-0.8,3.7-1.5c1.1-0.4,1.3,0.1,3.2-0.7
				c2.2-0.9,1.4-2.4,2-3.2c0.5-0.6,1.3-0.8,2.1-1.3c1.5-0.3,3-2.2,3-4.8c0-1.1-0.6-2.3-1-3.2c0-0.1,0.2-2.1-0.3-3.7
				c0.7,0.9,1.2,1.9,1.2,3.2c0,0.6,0,1.1-0.1,1.1c0.4,0.8,0.6,1.8,0.6,2.9C-353.8,140.7-354.9,142.8-356.4,143.1z"
					/>
				</g>
				<path
					fill="#C32028"
					d="M-353.2,135.7c0-0.1,0.2-2.3-0.4-4c-0.4-1.1-2.3-2.7-2.3-2.7s-0.1-1.1-0.6-2.1c-0.5-1-1.1-1.4-1.9-1.9
			c-0.9-0.4-1.2-0.6-1.4-1.2c-0.3-0.5-0.7-0.8-1.4-1.3c-0.8-0.5-2.1-0.3-2.9-0.3c-0.8,0.1-1.2,0-2-0.1c-0.8-0.1-1.9-1.1-3.5-1.2
			c-1.4,0-3.6,1.3-4.3,1.7c-0.1,0-0.1,0-0.2,0.1c-0.4,0.1-0.9-0.3-2.8,0.5c-1.4,0.5-1.7,1.8-1.9,2.6c-0.3,0.2-0.5,0.5-0.7,0.9
			c-0.5,0.2-1.4,0.7-2.6,1.9c-1.8,1.8-1,5.3-0.9,5.8c0.1,0.5-0.3,1.2-0.8,3.4c-0.2,1,0.4,2,1.2,2.8c0.7,0.9,1.2,0.9,1.8,1.9
			c0.6,1,0.1,1.1,0.6,2.1c0.5,1,1.1,1.4,1.9,1.9c0.9,0.4,1.2,0.6,1.4,1.2c0.3,0.5,0.7,0.8,1.4,1.3c0.8,0.5,2.1,0.3,2.9,0.3
			c0.8-0.1,1.2,0,2,0.1c0.8,0.1,1.9,1.1,3.5,1.2c1.6,0.1,2-0.8,3.9-1.5c1.2-0.4,1.4,0.1,3.3-0.7c2.3-0.9,1.4-2.4,2.1-3.2
			c0.5-0.6,1.3-0.8,2.2-1.3c1.5-0.3,2.7-2.5,2.7-5.1C-352.6,137.6-352.8,136.5-353.2,135.7z"
				/>
				<polygon
					fill="#C32028"
					points="-361.8,135.8 -361.8,135.5 -362.6,136.6 -362.4,136.6 -361.8,136.6 	"
				/>
				<path
					fill="#C32028"
					d="M-364.8,143.8c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0.3,0.6c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1C-364.7,143.9-364.7,143.9-364.8,143.8
			C-364.8,143.8-364.8,143.8-364.8,143.8z"
				/>
				<polygon
					fill="#C32028"
					points="-372.7,136.6 -372.6,136.6 -372.5,136.6 -372.5,136.3 -372,136.3 -372,136.2 -372,136 -372.7,136
			"
				/>
				<path
					fill="#C32028"
					d="M-361.9,142.6c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
			c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
			c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0
			c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
			C-361.9,142.7-361.9,142.6-361.9,142.6z"
				/>
				<path
					fill="#C32028"
					d="M-376.2,132.6C-376.2,132.6-376.2,132.6-376.2,132.6C-376.2,132.5-376.2,132.5-376.2,132.6L-376.2,132.6
			c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
			c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1l-0.1,0.1l0.2,0.1L-376.2,132.6z"
				/>
				<path
					fill="#C32028"
					d="M-375.7,135.9c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1-0.1c0,0,0-0.1,0.1-0.1
			c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0
			c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2
			C-375.8,135.8-375.8,135.8-375.7,135.9z"
				/>
				<path
					fill="#C32028"
					d="M-374.4,141.9C-374.4,141.9-374.4,141.8-374.4,141.9L-374.4,141.9C-374.4,141.8-374.4,141.8-374.4,141.9
			l-0.5,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
			c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1C-374.3,141.9-374.3,141.9-374.4,141.9z"
				/>
				<path
					fill="#C32028"
					d="M-375.7,140.1C-375.7,140.1-375.7,140.1-375.7,140.1c0-0.1,0-0.1,0-0.1l-0.1-0.1l-0.7,0.3c0,0,0,0,0,0
			c0,0,0,0,0,0l0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1
			c0,0,0.1-0.1,0.1-0.1C-375.7,140.2-375.7,140.1-375.7,140.1z"
				/>
				<polygon
					fill="#C32028"
					points="-372.7,144.1 -372.5,144.2 -372.5,143.9 -372.4,143.6 -372.8,144.1 	"
				/>
				<path
					fill="#C32028"
					d="M-375.9,137.3c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
			c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
			c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1
			c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2C-376,137.2-376,137.3-375.9,137.3z"
				/>
				<path
					fill="#C32028"
					d="M-367.1,144.7c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0
			c0,0-0.1,0-0.1,0l-0.1,0l0.2,1.4l0.1,0l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2C-367.1,144.9-367.1,144.8-367.1,144.7z"
				/>
				<polygon
					fill="#C32028"
					points="-370,133.5 -369.9,133.5 -369.8,133.5 -369.8,133.2 -369.5,133.2 -369.5,133.1 -369.5,132.9
			-370,132.9 	"
				/>
				<polygon
					fill="#C32028"
					points="-367.5,133.2 -367.2,133.2 -367.2,133.1 -367.2,132.9 -367.7,132.9 -367.7,133.5 -367.5,133.5
			-367.5,133.5 	"
				/>
				<polygon
					fill="#C32028"
					points="-369,133.5 -369,133.2 -368.7,133.2 -368.7,133.1 -368.7,132.9 -369.3,132.9 -369.3,133.5
			-369.1,133.5 	"
				/>
				<polygon
					fill="#C32028"
					points="-366.1,133.5 -366,133.5 -365.9,133.5 -365.9,133.2 -365.6,133.2 -365.6,133.1 -365.6,132.9
			-366.1,132.9 	"
				/>
				<path
					fill="#C32028"
					d="M-368.8,134c0.1-0.1,0.3-0.1,0.4,0l0.1-0.1l0,0l0,0c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0
			c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4l0.1-0.1l0.1-0.1c0-0.1-0.1-0.1-0.1-0.2C-368.9,134.1-368.9,134.1-368.8,134z"
				/>
				<polygon
					fill="#C32028"
					points="-368.3,133.2 -367.9,133.2 -367.9,133.1 -367.9,132.9 -368.5,132.9 -368.5,133.5 -368.3,133.5
			-368.3,133.5 	"
				/>
				<path
					fill="#C32028"
					d="M-368.7,135.6h0.2h0c0-0.8,0.6-1.4,1.3-1.4V134v-0.1v-0.1c-0.1,0-0.2,0-0.4,0
			C-368.2,134.1-368.7,134.8-368.7,135.6z"
				/>
				<polygon
					fill="#C32028"
					points="-368.4,136 -368.4,135.9 -368.7,135.9 -368.7,137.8 -368.6,137.8 -368.3,138.1 -368.2,138.1
			-368.2,138 -368.4,137.7 	"
				/>
				<path
					fill="#C32028"
					d="M-371.4,135.6c0-0.8,0.6-1.4,1.3-1.4V134v-0.1c-0.9,0-1.5,0.7-1.5,1.7H-371.4L-371.4,135.6z"
				/>
				<polygon
					fill="#C32028"
					points="-371.6,133.5 -371.4,133.5 -371.4,133.5 -371.4,133.2 -371.1,133.2 -371.1,133.1 -371.1,132.9
			-371.6,132.9 	"
				/>
				<polygon
					fill="#C32028"
					points="-365.1,136.6 -365.1,136.3 -364.6,136.3 -364.6,136.2 -364.6,136 -365.3,136 -365.3,136.6
			-365.2,136.6 	"
				/>
				<polygon
					fill="#C32028"
					points="-370.7,133.5 -370.6,133.5 -370.6,133.2 -370.3,133.2 -370.3,133.1 -370.3,132.9 -370.8,132.9
			-370.8,133.5 	"
				/>
				<polygon
					fill="#C32028"
					points="-366.9,133.5 -366.8,133.5 -366.7,133.5 -366.7,133.2 -366.4,133.2 -366.4,133.1 -366.4,132.9
			-366.9,132.9 	"
				/>
				<path
					fill="#C32028"
					d="M-368.9,131.6L-368.9,131.6c0-0.2,0-0.2,0-0.3c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
			c0.1,0,0.1,0,0.2,0.1l0.1-0.1l0,0c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.4
			C-369.1,131.4-369,131.5-368.9,131.6L-368.9,131.6z"
				/>
				<polygon
					fill="#C32028"
					points="-365.6,132.7 -365.6,132.6 -365.6,132.5 -371.6,132.5 -371.6,132.7 -371.4,132.7 	"
				/>
				<path
					fill="#641714"
					d="M-357.8,136.1c0-0.6-0.1-1.3-0.2-1.9c-0.6-3.1-2.5-5.8-5.3-7.3c-1.5-0.9-3.3-1.4-5.2-1.4
			c-4.8,0-8.9,3.2-10.2,7.6c-0.3,0.9-0.4,1.9-0.4,2.9c0,2.8,1.1,5.3,2.8,7.1c1.9,2.1,4.7,3.4,7.8,3.4
			C-362.6,146.6-357.8,141.9-357.8,136.1z M-368.5,146.4c-1.8,0-3.5-0.4-4.9-1.2c-3.2-1.7-5.4-5.1-5.4-9.1c0-1,0.2-2,0.4-2.9
			c1.3-4.3,5.3-7.4,10-7.4c3.1,0,5.8,1.3,7.7,3.4c1.2,1.4,2.1,3.1,2.5,5c0.1,0.6,0.2,1.2,0.2,1.9
			C-358.1,141.7-362.7,146.4-368.5,146.4z"
				/>
				<path
					fill="#641714"
					d="M-372,134.3l-0.3,0.5c-0.3,0.1-0.6,0.4-0.6,0.8h-0.3v3.2h0.1h0.3v-2h0.7v0.1v0.1h-0.5v1.7h0.1h1.4v-0.9
			l-0.2-0.1v-1.8h0.1l0.1-0.3l0,0h0h-0.2v0c0-0.7,0.5-1.2,1.1-1.2c0.3,0,0.6,0.2,0.8,0.4c0.2,0.2,0.3,0.5,0.3,0.7c0,0,0,0,0,0v0h-0.2
			l0.1,0.4h0.1v1.8l-0.2,0.1v0.9h0.1h0.9v-0.9l-0.2-0.1v-1.8h0.1l0.1-0.3l0,0h0h-0.2v0c0,0,0,0,0,0c0-0.6,0.5-1.1,1.1-1.1
			c0.3,0,0.6,0.2,0.8,0.4c0.2,0.2,0.3,0.5,0.3,0.8v0h-0.2l0.1,0.4h0.1v1.8l-0.2,0.1v0.9h0.1h0.8v-2h0.7v0.1v0.1h-0.5v1.7h0.1h0.9
			v-3.1v-0.1h-0.2h-0.2c0-0.3-0.1-0.5-0.3-0.7c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0l-0.3-0.5V134v-0.4l0-0.1l0.4-0.5l0.1-0.1h-0.4
			h-0.2v-0.7c-0.1-0.1-0.1-0.2-0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0-0.1,0-0.1h-0.6c0,0.2,0,0.4,0.1,0.5c0,0.1-0.1,0.1-0.1,0.2h-1
			h-0.1l-0.3-0.4l-0.1-0.1h-0.3h-0.2v-0.4c0-0.3-0.1-0.5-0.3-0.6c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.1
			c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0c-0.3,0.1-0.5,0.4-0.5,0.7v0.4h-0.5l-0.4,0.4h-1h-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0-0.1,0-0.1h-0.6c0,0.2,0,0.4,0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.4v0.7h-0.6
			l0.5,0.6l0,0.1v0.1V134.3z M-372,136.2L-372,136.2l-0.5,0.1v0.3h-0.1h-0.2V136h0.7V136.2z M-365.3,136h0.7v0.1v0.1h-0.5v0.3h-0.1
			h-0.2V136z M-369.5,133.1L-369.5,133.1l-0.3,0.1v0.4h-0.1h-0.2v-0.6h0.5V133.1z M-369.3,133.5v-0.6h0.5v0.1v0.1h-0.3v0.4h-0.1
			H-369.3z M-368.9,134.5L-368.9,134.5c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.1-0.4c0,0,0,0,0,0c0.2-0.2,0.5-0.2,0.7,0l0,0l0,0
			l-0.1,0.1c-0.1-0.1-0.3-0.1-0.4,0c0,0.1-0.1,0.1-0.1,0.2C-368.9,134.3-368.9,134.4-368.9,134.5L-368.9,134.5z M-368.5,133.5v-0.6
			h0.5v0.1v0.1h-0.3v0.4h-0.1H-368.5z M-368.2,138L-368.2,138L-368.2,138l-0.4-0.2l0,0v-1.9h0.2v0.1v1.7L-368.2,138z M-367.1,133.9
			L-367.1,133.9v0.2c-0.7,0-1.3,0.6-1.3,1.4h0h-0.2c0-0.8,0.5-1.4,1.2-1.6C-367.4,133.9-367.2,133.9-367.1,133.9L-367.1,133.9z
			M-367.7,133.5v-0.6h0.5v0.1v0.1h-0.3v0.4h-0.1H-367.7z M-366.4,133.1L-366.4,133.1l-0.3,0.1v0.4h-0.1h-0.2v-0.6h0.5V133.1z
			M-365.6,133.1L-365.6,133.1l-0.3,0.1v0.4h-0.1h-0.2v-0.6h0.5V133.1z M-368.9,130.9c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1
			l0,0l-0.1,0.1c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.2l-0.1,0.1l-0.1,0.1
			c-0.1-0.1-0.1-0.2-0.1-0.4C-369.1,131.1-369,131-368.9,130.9z M-371.6,132.5h6v0.1v0.1h-5.9h-0.2V132.5z M-370.3,132.9v0.1v0.1
			h-0.3v0.4h-0.1h-0.2v-0.6H-370.3z M-371.1,132.9v0.1v0.1h-0.3v0.4h-0.1h-0.2v-0.6H-371.1z M-370.1,133.9v0.1v0.1
			c-0.7,0-1.3,0.6-1.3,1.4h0h-0.2C-371.6,134.6-370.9,133.9-370.1,133.9z M-371.6,135.9h0.2v0.1v1.7l0.3,0.3l-0.1,0.1l0,0l-0.4-0.3
			l0,0V135.9z"
				/>
				<path
					fill="#641714"
					d="M-361.8,138.6h-0.2c-1,2.6-3.5,4.5-6.5,4.5c-1.1,0-2.1-0.3-3.1-0.7c-1.5-0.8-2.8-2.1-3.4-3.7
			c0,0,0-0.1,0-0.1h-0.2c0.3,0.9,0.8,1.7,1.5,2.4c1.3,1.4,3.2,2.3,5.3,2.3C-365.5,143.3-362.9,141.4-361.8,138.6
			C-361.8,138.7-361.8,138.7-361.8,138.6z"
				/>
				<path
					fill="#641714"
					d="M-370.6,144.4l0.1,0l0,0l0.1-0.3c0,0,0,0,0,0l0.5,0.1l-0.1,0.1l-0.9,1.1l0,0.1l0.1,0l1,0.2l0.1-0.4l0-0.1
			l-0.2,0l-0.1,0.3c0,0,0,0,0,0l-0.3-0.1l-0.2,0l1-1.2l0-0.1l0-0.1l-1.1-0.2L-370.6,144.4z"
				/>
				<path
					fill="#641714"
					d="M-373.4,144.4l-0.1,0l-0.1,0.1l0.1,0.1l0.3,0.2l0.1-0.1l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.3-0.3l0.4,0.2
			l0,0.1l-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0l-0.1,0.1l0.1,0.1l0.4,0.2l0.1-0.1l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.3-1.6l0,0
			l-0.2-0.1L-373.4,144.4z M-372.5,143.9l0,0.3l-0.2-0.1l-0.2-0.1l0.4-0.4L-372.5,143.9z"
				/>
				<path
					fill="#641714"
					d="M-361.8,137.7h0.1h0.4v-0.8h0.3v-0.3v-0.1h-0.2h-0.2v-1.7v-0.1h-0.5l-1.4,1.7v0.4h0.1h1.2v0.1V137.7z
			M-362.4,136.6h-0.3l0.9-1.1v0.3v0.8H-362.4z"
				/>
				<path
					fill="#641714"
					d="M-363.6,139.4C-363.5,139.4-363.5,139.4-363.6,139.4l-10-0.1c0.1,0.1,0.1,0.2,0.2,0.2h0h9.7
			C-363.6,139.5-363.6,139.5-363.6,139.4z"
				/>
				<path
					fill="#641714"
					d="M-358.8,137.2h-0.9h-0.3c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.2-0.3,0.3-0.4
			c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.2-0.1
			c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0v-0.6c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0
			c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
			c-0.1,0.1-0.2,0.2-0.3,0.3s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.2,0.2v0.3h0.1h2v-0.7v-0.1h-0.3
			L-358.8,137.2z"
				/>
				<path
					fill="#641714"
					d="M-363.9,144.6C-363.9,144.6-363.9,144.6-363.9,144.6C-364,144.6-364,144.6-363.9,144.6c-0.1,0-0.2,0-0.2,0
			c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0-0.1,0-0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.7,0.3
			l0.1,0.1l0.1,0l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l0.5,1c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0.1l0.1,0.1l0.1,0l0.5-0.3l0,0l-0.1-0.1
			l-0.1,0l-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.2-0.5l0,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
			l-0.1-0.1C-363.8,144.5-363.8,144.5-363.9,144.6z M-364.8,144.1C-364.8,144.1-364.8,144.2-364.8,144.1c-0.1,0.1-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.3-0.6c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3,0c0.1,0,0.1,0.1,0.2,0.2
			c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2C-364.7,144-364.7,144.1-364.8,144.1z"
				/>
				<path
					fill="#641714"
					d="M-375.1,133.5c-0.1,0.2-0.1,0.3-0.2,0.5h0.1h0.1c0.1-0.2,0.1-0.3,0.2-0.5c1.1-2.4,3.5-4.1,6.3-4.1
			c2,0,3.9,0.9,5.1,2.3c0.6,0.7,1.1,1.5,1.4,2.3h0.1h0.2c-0.6-1.7-1.8-3.2-3.4-4c-1-0.5-2.2-0.9-3.4-0.9
			C-371.5,129.2-374.1,131-375.1,133.5z"
				/>
				<polygon
					fill="#641714"
					points="-367.2,126.7 -367,126.7 -366.7,126.4 -366.7,126.3 -366.9,126.3 -367,126.5 -367.1,126.5
			-367.2,126.3 -367.3,126.3 -367.5,126.2 -367.3,126.7 	"
				/>
				<path
					fill="#641714"
					d="M-370.8,127.2L-370.8,127.2C-370.7,127.2-370.7,127.2-370.8,127.2c0.1,0,0.1,0,0.1,0l0,0.1l0.2,1.2
			c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0l0,0.1l0.1,0l1.1-0.2l-0.1-0.3l0-0.1l-0.1,0l0,0l0,0l0,0.2c0,0,0,0,0,0c0,0,0,0,0,0l-0.3,0.1
			l-0.1,0l-0.1-0.6l0.3,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0.1l0,0.1l0.1,0l0.1,0l-0.1-0.5l0-0.1l-0.1,0l0,0.2c0,0,0,0,0,0c0,0,0,0,0,0
			l-0.1,0l-0.1,0l-0.1-0.6l0.4-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0,0.1l0,0.1l0.1,0l0.1,0l-0.1-0.3l0-0.1l-1.1,0.2L-370.8,127.2
			L-370.8,127.2z"
				/>
				<path
					fill="#641714"
					d="M-359.8,131.9l0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0l-0.5-0.1L-359.8,131.9L-359.8,131.9z
			"
				/>
				<path
					fill="#641714"
					d="M-365.3,129.1C-365.3,129.1-365.3,129.2-365.3,129.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1
			c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1-0.1-0.1
			c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0l-0.1,0.3l0.1,0.1
			l0,0l0.2-0.4c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0
			c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.3l0,0l-0.2-0.1l-0.2,0.4
			C-365.4,129-365.4,129.1-365.3,129.1C-365.4,129.1-365.3,129.1-365.3,129.1z"
				/>
				<path
					fill="#641714"
					d="M-373.2,128.6l0.1-0.1l-0.1-0.3c0,0,0,0,0,0l0.4-0.2l0,0.2l0,1.4l0.1,0.1l0,0l1-0.5l-0.2-0.3l-0.1-0.1
			l-0.1,0.1l0.1,0.3c0,0,0,0,0,0l-0.3,0.2l-0.2,0.1l0-1.6l0,0l-0.1-0.1l-1,0.5L-373.2,128.6L-373.2,128.6z"
				/>
				<path
					fill="#641714"
					d="M-375.5,130.1C-375.5,130.1-375.5,130.1-375.5,130.1C-375.5,130.1-375.5,130.1-375.5,130.1
			C-375.5,130.1-375.4,130.1-375.5,130.1l1,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0.1l0.1,0.1l0.1-0.1l0.7-0.7l-0.3-0.2
			l-0.1-0.1l-0.1,0.1l0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.2,0.3l0,0l-1-0.9c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0l0.1-0.1l-0.1-0.1l-0.5,0.5L-375.5,130.1L-375.5,130.1z"
				/>
				<path
					fill="#641714"
					d="M-377.1,132.6C-377.1,132.6-377.1,132.6-377.1,132.6C-377,132.6-377,132.5-377.1,132.6
			C-377,132.5-377,132.5-377.1,132.6l1.2,0.5c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0.1l0.1,0.1l0-0.1l0.2-0.4l0.1-0.2l-0.1-0.1l-0.1,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0l-0.2-0.1l-0.2-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
			c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
			c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1s0,0.1-0.1,0.1l-0.3,0.6l0.1,0.1L-377.1,132.6z M-376.8,132.1
			C-376.8,132.1-376.8,132.1-376.8,132.1c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1
			c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.5-0.2l-0.2-0.1L-376.8,132.1z"
				/>
				<path
					fill="#641714"
					d="M-367,144.3C-367,144.3-367,144.3-367,144.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0l-0.7,0.1l0,0.1
			l0.1,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0.1l0.1,1.2c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0l0,0.1l0.1,0l0.6-0.1c0.1,0,0.2,0,0.3-0.1
			c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2-0.1-0.3C-366.9,144.5-366.9,144.4-367,144.3z
			M-367.1,145.4c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0l0,0l-0.1,0l-0.2-1.4l0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2
			c0,0.1,0,0.2,0,0.2C-367.1,145.3-367.1,145.3-367.1,145.4z"
				/>
				<path
					fill="#641714"
					d="M-376.8,137.5L-376.8,137.5l-0.3-0.1l-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0-0.1v-2.5v-0.1h-0.3l-0.9,0.3v0.2
			l0.1,0l0.5-0.1v0.2v1.9c0,0,0,0,0,0.1c0,0,0,0-0.1,0l-0.4,0v0.2h0.1h1.4V137.5z"
				/>
				<path
					fill="#641714"
					d="M-365.8,141.4c0.1,0,0.1-0.1,0.2-0.1h-5.9c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2h4.9
			C-366,141.6-365.9,141.5-365.8,141.4z"
				/>
				<path
					fill="#641714"
					d="M-368.6,142.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6-0.1h-2.2c0.2,0,0.4,0.1,0.6,0.1
			C-368.9,142.1-368.7,142.1-368.6,142.1z"
				/>
				<path
					fill="#641714"
					d="M-360.3,140.5c0,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0l-0.6-0.3l-0.1-0.1c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
			l0.1-0.1l-0.1-0.1l-0.3,0.6l0.1,0.1l0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.8,0.4c0.1,0,0.2,0.1,0.2,0.1
			c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.3-0.1l-0.1,0.2
			l0.1,0.1l0.3,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.2
			c0-0.1,0-0.1,0-0.2C-360.2,140.6-360.2,140.5-360.3,140.5z"
				/>
				<path
					fill="#641714"
					d="M-376.2,136.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3
			c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2
			c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0
			c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
			c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.2h0c-0.1-0.1-0.2-0.1-0.3-0.1
			c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.4
			c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0-0.1,0
			C-376.1,136.3-376.2,136.3-376.2,136.3z M-375.9,135.3c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
			c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1
			c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2
			c0-0.1-0.1-0.1-0.1-0.2C-375.9,135.4-375.9,135.4-375.9,135.3z M-376,136.7c0-0.1,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
			c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1
			c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
			c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2
			C-376.1,136.8-376.1,136.8-376,136.7z"
				/>
				<path
					fill="#641714"
					d="M-361.9,142.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0
			c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3
			c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.2
			c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3C-361.7,142.2-361.8,142.1-361.9,142.1z M-361.9,143
			c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1
			c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
			c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1
			c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1C-361.9,142.9-361.9,143-361.9,143z"
				/>
				<path
					fill="#641714"
					d="M-364.8,140.8C-364.7,140.7-364.7,140.7-364.8,140.8l-7.7-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2
			l7.2,0C-364.9,140.9-364.8,140.8-364.8,140.8z"
				/>
				<path
					fill="#641714"
					d="M-364.1,140.1C-364,140.1-364,140-364.1,140.1l-9-0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1h8.7
			C-364.2,140.2-364.1,140.1-364.1,140.1z"
				/>
				<path
					fill="#641714"
					d="M-375.6,140.6c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1
			l-0.2-0.4l-0.1-0.1l-0.1,0.1l0,0.1c0,0,0,0,0,0c0,0,0,0,0,0l-1.1,0.6l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1l-0.1,0.1
			l0.3,0.6l0,0l0.1,0l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.4-0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1
			C-375.7,140.7-375.6,140.6-375.6,140.6z M-375.9,140.4c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1
			c0,0-0.1-0.1-0.1-0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.7-0.3l0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1
			C-375.8,140.3-375.8,140.4-375.9,140.4z"
				/>
				<path
					fill="#641714"
					d="M-374,142C-374,142-374.1,142-374,142c-0.1-0.1-0.1-0.1-0.1-0.2l-0.4-0.4l-0.1-0.1l-0.1,0.1l0.1,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0l-0.9,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1l-0.1,0.1l0.1,0.1l0.4,0.4l0.1-0.1
			l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.4-0.4l0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
			c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0.1l0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
			c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
			C-374,142.1-374,142.1-374,142z M-374.4,142.3c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.5-0.4c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1
			C-374.3,142.2-374.3,142.2-374.4,142.3z"
				/>
				<path
					fill="#641714"
					d="M-367.6,128.5l0-0.1l0,0l-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0.2-1.1l0.1,0.3l0.4,1.1l0.1,0l0.2,0l0.2-1.5
			c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0l0-0.1l0-0.1l-0.5-0.1l0,0.1l0.1,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l-0.1,0.7l0,0.2l-0.5-1.2l0,0
			l0-0.1l-0.6-0.1l0,0.1l0.1,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l-0.2,1.2c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0l0,0.1l0.1,0L-367.6,128.5z"
				/>
				<path
					fill="#641714"
					d="M-361.4,132.5C-361.4,132.5-361.4,132.5-361.4,132.5C-361.5,132.5-361.5,132.5-361.4,132.5L-361.4,132.5
			l-0.1-0.1l-0.1,0.1l0.3,0.6l0,0l0.1,0l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.5-0.2l0.9,0l0.1,0.1l0.1-0.1l-0.2-0.3l-0.1-0.1l-0.1,0.1
			l0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.4,0l-0.2,0l0.4-0.5c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l0.1-0.1l-0.2-0.5l-0.1-0.1l-0.1,0.1
			l0,0.1l-0.6,0.8L-361.4,132.5L-361.4,132.5z"
				/>
				<path
					fill="#641714"
					d="M-363.1,130.7L-363.1,130.7l0-0.2c0,0,0,0,0,0c0,0,0,0,0,0l0.4-0.4l0.1,0.1l0,0.1l-0.2,0.6l-0.1,0.1
			l0.1,0.1l0.2,0.2l0.1-0.1l-0.1-0.1l0.3-0.9l0.7-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.1l-0.3-0.3l-0.1-0.1l-0.1,0.1
			l0.1,0.1c0,0,0,0,0,0s0,0,0,0l-0.6,0.1l-0.2,0l0,0l0.4-0.4c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1l0.1-0.1l-0.4-0.4l-0.1-0.1l-0.1,0.1
			l0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.9,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1l-0.1,0.1l0.1,0.1
			L-363.1,130.7z"
				/>
				<path
					fill="#641714"
					d="M-379,143.2c-0.2-0.2-0.5-0.4-0.7-0.6c0.8,1,0,1.3,1,2.5c0.4,0.4,0.8,0.8,1.2,1.1c0,0,0,0,0-0.1
			C-378.7,144.8-377.5,144.6-379,143.2z"
				/>
				<path
					fill="#641714"
					d="M-355.8,139.8c0.1,0.3,0.1,0.6,0.2,0.9c0-1.2,0.8-1,0.7-2.6c0-0.6-0.1-1.1-0.3-1.6c0,0,0,0,0,0.1
			C-355.1,138.3-356.2,137.8-355.8,139.8z"
				/>
				<path
					fill="#641714"
					d="M-359.4,143.9c-0.6,0.8,0.2,2.2-2,3.2c-1.8,0.8-2.1,0.3-3.2,0.7c-1.8,0.6-2.2,1.5-3.7,1.5
			c-1.5-0.1-2.6-1.1-3.3-1.2c-0.7-0.1-1.2-0.2-1.9-0.1c-0.6,0-1.7,0.2-2.5-0.1c0.2,0.1,0.3,0.3,0.6,0.4c0.7,0.5,2.1,0.3,2.8,0.3
			c0.7-0.1,1.2,0,1.9,0.1c0.7,0.1,1.8,1.1,3.3,1.2c1.5,0.1,1.9-0.8,3.7-1.5c1.1-0.4,1.3,0.1,3.2-0.7c2.2-0.9,1.4-2.4,2-3.2
			c0.5-0.6,1.3-0.8,2.1-1.3c1.5-0.3,2.6-2.4,2.6-5c0-1.1-0.2-2.1-0.6-2.9c0,0,0.1-0.5,0.1-1.1c0-1.2-0.5-2.3-1.2-3.2
			c0.5,1.6,0.3,3.6,0.3,3.7c0.4,0.8,1,2.1,1,3.2c0,2.6-1.5,4.4-3,4.8C-358.1,143-358.9,143.2-359.4,143.9z"
				/>
				<path
					fill="#641714"
					d="M-356.7,129.4c-0.2-0.9,0-1.2-0.5-2c-0.5-0.7-1.8-1.6-1.8-1.6c2.2,2.4,2.4,5.2,2.4,5.2
			S-356.5,130-356.7,129.4z"
				/>
				<path
					fill="#641714"
					d="M-382.4,137.6c0.4-1.9,0.8-2.6,0.7-3c0-0.4-0.8-3.5,0.9-5.1c1.2-1.1,2-1.5,2.5-1.7c0.2-0.3,0.4-0.6,0.6-0.8
			c0.2-0.7,0.5-1.8,1.8-2.3c1.8-0.7,2.3-0.3,2.6-0.4c0,0,0.1,0,0.1-0.1c0.6-0.3,2.7-1.5,4-1.5c1.5,0,2.6,0.9,3.3,1
			c0.7,0.1,1.2,0.1,1.9,0.1c0.7,0,2-0.2,2.7,0.2c0.4,0.3,0.7,0.5,0.9,0.7c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c-0.3-0.5-0.7-0.7-1.4-1.2
			c-0.7-0.5-2-0.3-2.7-0.2c-0.7,0-1.2,0-1.9-0.1c-0.7-0.1-1.8-1-3.3-1c-1.4,0-3.4,1.2-4,1.5c0,0-0.1,0-0.1,0.1
			c-0.4,0.1-0.8-0.2-2.6,0.4c-1.3,0.5-1.6,1.6-1.8,2.3c-0.3,0.2-0.5,0.5-0.6,0.8c-0.5,0.2-1.3,0.6-2.5,1.7c-1.7,1.6-0.9,4.7-0.9,5.1
			c0.1,0.4-0.3,1-0.7,3c-0.1,0.7,0.2,1.4,0.7,2C-382.3,138.5-382.5,138.1-382.4,137.6z"
				/>
				<path
					fill="#641714"
					d="M-370.2,124.4c-1.1,0.1-3,0.1-4.7,1.4c-1.5,1.2-3.6,4-3.6,4c-0.8,0.8-1.3,2.1-1.5,3.3
			c-0.2,0.9-0.2,1.6-0.2,2c0,0.7-0.1,1.7,0.2,4.2c0.3,2.5,1.6,3.6,1.9,4.1c0.3,0.5,1.4,1.1,2.8,2c1.4,0.9,2.9,1.4,2.9,1.4
			c3.2,0.9,5.2,0.6,5.8,0.4c0.6-0.1,2.2-0.6,2.2-0.6s-1.4,0.4-1.9,0.5c-0.5,0.1-2.4,0.4-5.5-0.4c0,0-1.4-0.5-2.7-1.3
			c-1.3-0.9-2.2-1.7-2.5-2.1c-0.3-0.4-1.6-1.4-1.9-3.8c-0.3-2.4-0.2-3.3-0.2-4c0-0.4,0.1-1.3,0.3-2.3c0.3-1,0.7-2.1,1.4-2.7
			c0,0,2-2.6,3.5-3.8c1.6-1.3,3.5-1.3,4.5-1.3c1.1-0.1,4-0.6,7.7,1.5c0,0,2.3,1.3,3,2.5c0.7,1.1,1.7,2.5,1.8,4.5l0.1,0.5l0.2,1.3
			c0,0,0,1.4-0.2,2.4c-0.2,1-0.1,2.1-1.2,4.1c0,0,0.4-0.5,0.6-0.8c0.9-1.5,0.7-2.6,0.9-3.6c0.2-1,0.2-2.5,0.2-2.5l-0.1-1l-0.1-1
			c-0.1-2.1-1.1-3.5-1.9-4.7c-0.8-1.2-3.2-2.6-3.2-2.6C-366,123.7-369.1,124.3-370.2,124.4z"
				/>
			</g>
		</svg>

		<hr />

		<svg xmlns="http://www.w3.org/2000/svg" viewBox="-929 531 60 19" fill="currentColor">
			<title>Kozel</title>
			<path d="M-882.25,544.187l0.079,0.003c1.223-0.036,2.156-0.28,2.858-0.589c0.313-0.136,0.906-0.489,1.152-0.766
				c0.372-0.421,0.817-1.183,0.245-1.911c-0.082,0.059-0.426,0.348-0.595,0.51c-0.772,0.747-1.763,1.073-2.927,1.125
				c-0.141,0.011-0.29,0.017-0.45,0.017c-0.896,0-1.648-0.395-1.93-1.232c-0.197-0.58-0.218-1.23-0.215-1.557
				c0-0.122,0.004-0.13,0.004-0.214h6.86c0.004-0.097,0.013-0.212,0.014-0.391c0.004-0.495-0.061-1.387-0.669-2.259
				c-0.638-0.915-2.192-1.877-4.657-1.877l-0.215,0.001c-2.716,0-5.617,1.565-5.617,4.597
				C-888.312,542.854-885.021,544.187-882.25,544.187z M-884,538.008c0.068-0.587,0.372-1.748,1.542-1.748
				c1.289,0,1.521,1.401,1.562,1.903c-0.009,0.107-0.07,0.177-0.286,0.177h-2.839C-884.029,538.195-884.013,538.113-884,538.008z"/>
			<path d="M-905.876,535.034c-2.709,0-6.064,1.19-6.064,4.61c0,3.381,3.355,4.532,6.064,4.532c2.861,0,6.064-1.251,6.064-4.532
				C-899.812,536.433-902.928,535.034-905.876,535.034z M-905.94,542.984c-1.247,0-1.88-1.473-1.88-3.289
				c0-1.78,0.615-3.289,1.88-3.289c1.247,0,1.88,1.473,1.88,3.289C-904.06,541.284-904.576,542.984-905.94,542.984z"/>
			<path d="M-900.062,546.537c-0.214,0.03-0.419,0.07-0.626,0.086c0.064,0.181,0.414,0.622,0.995,1.35
				c0.879,1.102,1.923,0.831,2,0.819c12.001-1.807,22.611-0.984,27.282-0.995c0.221,0,1.375,0.136,1.411-1.038
				C-869.48,546.474-884.688,544.308-900.062,546.537z"/>
			<path d="M-899.131,536.753c0.209,0.858,0.427,1.752,0.427,1.752l0.788-0.197c0,0-0.187-1.605,1.971-1.605
				c0.422,0,1.733-0.02,1.733-0.02l-5.11,6.322h0.007v0.9c0.82-0.002,10.751,0.002,10.751,0c-0.004-0.011-0.93-3.297-0.93-3.297
				l-0.788,0.197c0,0,0.185,1.606-1.971,1.606c-0.691,0-2.155,0.029-2.155,0.029l5.056-6.247v-0.994h-7.716
				c-1.138-0.021-1.876-0.102-2.248-0.162c-0.233-0.037-0.2,0.168-0.187,0.224C-899.415,535.628-899.198,536.489-899.131,536.753z"/>
			<path d="M-875.305,541.268c0,1.634-0.953,1.542-1.647,1.666l-0.011,0.971h7.387l-0.01-0.971c-0.694-0.124-1.648-0.032-1.648-1.666
				v-4.458v-3.331c0.036-1.038,0.124-1.694,0.169-1.94c0.031-0.168-0.074-0.208-0.169-0.211v-0.011l-5.719,0.876l0.011,0.971
				c0.694,0.124,1.647,0.032,1.647,1.666l-0.008,0.758L-875.305,541.268z"/>
			<path d="M-910.906,544.283c-0.187-0.168-1.318-1.219-2.506-2.32c-0.657-0.609-1.804-1.619-3.246-2.49
				c1.28-0.922,3.622-2.657,4.946-3.673c1.698-1.307,3.757-2.521,5.635-3.174c1.545-0.536,3.013-0.571,3.013-0.571
				s-1.162-1.468-5-0.297c-1.159,0.353-2.492,1.224-2.492,1.224s-0.218-0.735-0.976-1.202c-0.759-0.467-1.896-0.8-3.55-0.8
				c-2.111,0-4.304,0.699-4.304,2.178c0,1.512,1.858,1.71,2.8,1.698c1.028-0.014,1.083-0.466,1.006-0.713
				c-0.237,0.052-0.618,0.099-1.011-0.005c-0.598-0.161-0.898-0.588-0.834-0.979c0.055-0.354,0.503-0.826,2.461-0.826
				c1.915,0,2.396,0.679,2.396,1.181c0,0.504-0.265,0.89-1.059,1.573c-0.59,0.505-2.603,1.914-4.784,3.485
				c-1.08-0.454-2.255-0.76-3.469-0.76c-0.179-0.002-0.292,0.005-0.442-0.007v-2.697v-0.039v-0.793c0-1.634,1.486-1.541,2.18-1.666
				l0.011-0.97h-8.867l0.011,0.97c0.694,0.125,2.18,0.032,2.18,1.666v0.793l-0.004,0.063v3.043c-0.897,0.298-1.922,0.741-1.922,1.715
				c0,0.764,0.732,1.268,1.922,1.61v2.848l0.001,0.625v0.644c0,1.634-1.486,1.393-2.179,1.517l-0.012,0.97h8.867l-0.011-0.97
				c-0.693-0.124-2.18,0.117-2.18-1.517v-0.644l0.003-0.261v-2.794c1.024-0.087,1.804-0.288,2.301-0.451
				c0.096-0.031,0.678-0.249,0.915-0.351c0.824,0.93,1.279,1.541,1.279,1.541c1.514,1.888,5.492,7.033,11.286,7.033
				c6.104,0,4.861-2.187,4.861-2.187S-905.524,549.135-910.906,544.283z M-920.438,539.864c-0.177,0.131-0.949,0.855-3.01,0.855
				c-1.511,0-3.033-0.355-3.033-0.844v-0.001v-0.001v-0.006v-0.021c0-0.481,1.548-0.84,3.033-0.84c2.077,0,2.833,0.724,3.01,0.856
				c0,0-0.001,0-0.002,0.001C-920.44,539.863-920.438,539.863-920.438,539.864z"/>
		</svg>
	</div>
)
